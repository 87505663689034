<template>
  <div>
    <v-card
      flat
      class="datatable-card-background datatable-card-border rounded"
    >
      <v-card-text>
        <v-row>
          <v-col
            cols="12"
            sm="auto"
          >
            <app-branch-select
              v-model="queryParams.branch_id"
              classes="small-input"
            />
          </v-col>

          <v-col
            cols="12"
            sm="4"
            md="3"
          >
            <app-date-picker
              v-model="queryParams.start_date"
              hide-details
              no-title
              classes="small-input"
              :label="$t('Start Date')"
            />
          </v-col>

          <v-col
            cols="12"
            sm="4"
            md="3"
          >
            <app-date-picker
              v-model="queryParams.end_date"
              hide-details
              no-title
              classes="small-input"
              :label="$t('End Date')"
            />
          </v-col>

          <v-col class="text-end">
            <v-btn
              color="primary"
              depressed
              small
              @click="getData()"
            >
              <v-icon left>
                mdil-magnify
              </v-icon>

              {{ $t('Search') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <v-row
      class="mt-6"
      justify="center"
    >
      <v-col
        cols="12"
        sm="6"
        md="3"
      >
        <v-card
          flat
          class="datatable-card-background datatable-card-border rounded"
        >
          <v-card-text class="text-center error--text">
            <div class="text-subtitle-1 my-2">
              {{ $t('Total Expense') }}
            </div>
            <div class="text-h5 my-2">
              {{ $_format_number(data.total_expense) }} $
            </div>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col
        cols="12"
        sm="6"
        md="3"
      >
        <v-card
          flat
          class="datatable-card-background datatable-card-border rounded"
        >
          <v-card-text class="text-center primary--text">
            <div class="text-subtitle-1 my-2">
              {{ $t('Total Sale') }}
            </div>
            <div class="text-h5 my-2">
              {{ $_format_number(data.total_sale) }} $
            </div>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col
        cols="12"
        sm="6"
        md="3"
      >
        <v-card
          flat
          class="datatable-card-background datatable-card-border rounded"
        >
          <v-card-text class="text-center warning--text">
            <div class="text-subtitle-1 my-2">
              {{ $t('Total Sale Cost') }}
            </div>
            <div class="text-h5 my-2">
              {{ $_format_number(data.total_sale_cost) }} $
            </div>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col
        cols="12"
        sm="6"
        md="3"
      >
        <v-card
          flat
          class="datatable-card-background datatable-card-border rounded"
        >
          <v-card-text class="text-center success--text">
            <div class="text-subtitle-1 my-2">
              {{ $t('Profit') }}
            </div>
            <div class="text-h5 my-2">
              {{ $_format_number(data.profit) }} $
            </div>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col
        cols="12"
        sm="12"
        md="10"
        lg="7"
        xl="6"
      >
        <v-card
          flat
          class="datatable-card-background datatable-card-border rounded"
        >
          <v-card-text>
            <Bar
              :chart-options="chartOptions"
              :chart-data="chartData"
              :plugins="plugins"
              :css-classes="cssClasses"
              :styles="styles"
              :width="width"
              :height="height"
            />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import axios from 'axios'
import { Bar } from 'vue-chartjs/legacy'
import { Chart as ChartJS, Title, Tooltip, BarElement, CategoryScale, LinearScale } from 'chart.js'

ChartJS.register(Title, Tooltip, BarElement, CategoryScale, LinearScale)

export default {
  components: {
    Bar
  },

  data() {
    return {
      data: {},
      queryParams: {},
      chartData: {
        labels: [
          this.$t('Total Expense'),
          this.$t('Total Sale'),
          this.$t('Total Sale Cost'),
          this.$t('Profit')
        ],
        datasets: [{ data: [] }]
      },
      chartOptions: {
        responsive: true
      },
      plugins: [],
      cssClasses: '',
      styles: {},
      width: 110,
      height: 70
    }
  },

  mounted() {
    this.$_section_title({ title: 'Profit Report', icon: 'mdil-chart-bar' })
  },

  methods: {
    getData() {
      axios.post('report/profit/', this.queryParams).then(res => {
        this.data = res.data
        this.chartData.datasets = [{
          label: 'Chart',

          data: [
            res.data.total_expense,
            res.data.total_sale,
            res.data.total_sale_cost,
            res.data.profit
          ],

          backgroundColor: [
            'rgba(255, 99, 132, 0.2)',
            'rgba(54, 162, 235, 0.2)',
            'rgba(255, 206, 86, 0.2)',
            'rgba(75, 192, 192, 0.2)',
          ],

          borderRadius: 6,

          borderSkipped: 'start'
        }]
      })
    }
  },
}
</script>

<style lang="scss" scoped>
</style>